import {
  Box,
  Button,
  CardActions,
  Stack,
  TextField,
  Typography,
  Grid,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import {
  DataGrid,
  GridColumnGroupingModel,
  GridColumns,
  GridEnrichedColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { useState } from 'react'
import { TIMEZONES } from '../../constants'
import { fetchAdmin } from '../../services/fetch'
import { numberFormat } from '../../services/number'

interface FormData {
  from: moment.Moment
  to: moment.Moment
  timezone: string
  storeCountryCode: string
  daysAfter: number
}

const DESCRIPTION_UPDATE_FULL_NAME = 'フルネームを更新した数'
const DESCRIPTION_UPDATE_AVATAR_URL = 'アバター画像を更新した数'
const DESCRIPTION_UPDATE_BIO = 'Bioを更新した数'
const DESCRIPTION_MOMENT = 'Momentの投稿数'
const DESCRIPTION_MOMENT_LIKE = 'MomentのLike数（1人のユーザーが1~10の間でLikeしている）'
const DESCRIPTION_MOMENT_COMMENT = 'Momentのコメント数'
const DESCRIPTION_CHATROOM = 'チャットルームの作成数（Private、Peekable、Open）'
const DESCRIPTION_CHATROOM_JOIN = 'チャットルームへの参加数（Private、Peekable、Open）'
const DESCRIPTION_CHATROOM_LEAVE = 'チャットルームからの退出数（Private、Peekable、Open）'
const DESCRIPTION_CHATROOM_MESSAGE = 'チャットルームでのメッセージ送信数（Private、Peekable、Open）'
const DESCRIPTION_FRIEND_REQUEST = 'フレンドリクエストの送信数'
const DESCRIPTION_FRIEND_ACCEPT = 'フレンドリクエストを承認した数'
const DESCRIPTION_CONTACTS_SYNC_BANNER = '連絡先同期バナーをクリックした数'
const DESCRIPTION_PHONEBOOK_SYNC = '電話帳を同期した数'
const DESCRIPTION_PHONEBOOK_SEND_SMS = 'Bulk InviteでのSMS送信数'

const initColumns: GridColumns = [
  {
    sortable: false,
    field: 'id',
  },
  {
    sortable: false,
    field: 'ymd',
    align: 'center',
    valueGetter: ({ value }) => moment(value.toString()).format('YYYY/MM/DD'),
  },
  {
    sortable: false,
    field: 'days',
    align: 'center',
    width: 60,
    valueGetter: ({ row }) => moment(row.ymd.toString()).format('ddd'),
    renderCell: ({ row }) => {
      const days = moment(row.ymd.toString())
      const isWeekEnd = parseInt(days.format('E')) >= 6

      return (
        <Typography variant="body2" color={isWeekEnd ? 'red' : undefined}>
          {days.format('ddd')}
        </Typography>
      )
    },
  },

  // User
  {
    sortable: false,
    field: 'user_total',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['user total']}
        description="電話番号登録済みの合計ユーザー数"
      />
    ),
  },
  {
    sortable: false,
    field: 'firstOpen_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['first open']}
        description="インストール後はじめてアプリを開いた人数"
      />
    ),
    renderCell: (params: any) => {
      const storeCountryCode = params.row.firstOpen_storeCountryCode
      return (
        <Tooltip title={storeCountryCode}>
          <span>{params.value}</span>
        </Tooltip>
      )
    },
  },
  {
    sortable: false,
    field: 'sendCodeButtonFirstTap_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['tap', 'send button']}
        description="電話番号を入力してボタンをタップした人数"
      />
    ),
    renderCell: (params: any) => {
      const storeCountryCode = params.row.firstOpen_storeCountryCode
      return (
        <Tooltip title={storeCountryCode}>
          <span>{params.value}</span>
        </Tooltip>
      )
    },
  },
  {
    sortable: false,
    field: 'user_new',
    renderHeader: () => (
      <MultilineHeader
        names={['user new']}
        description="電話番号認証を完了した人数（ユーザー登録完了）"
      />
    ),
  },
  {
    sortable: false,
    field: 'user_registration_rate',
    renderHeader: () => (
      <MultilineHeader
        names={['user new', '/', 'first open']}
        description="user new / first open の数値で電話番号認証を完了した割合（ユーザー登録完了）"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'user_quit',
    headerName: 'user quit',
    renderHeader: () => (
      <MultilineHeader
        names={['user quit']}
        description="解約したユーザー数"
      />
    ),
    renderCell: ({ value }) => {
      const exist = !!parseInt(value)

      return (
        <Typography variant="body2" color={exist ? 'red' : undefined}>
          {exist ? '-' : null}
          {numberFormat(value)}
        </Typography>
      )
    },
  },
  {
    sortable: false,
    field: 'dau',
    headerName: 'dau',
    renderHeader: () => (
      <MultilineHeader
        names={['dau']}
        description="Daily Active User"
      />
    ),
    renderCell: ({ value }) => `${value}`,
  },
  // ppl update User
  {
    sortable: false,
    field: 'updateFullName_ppl',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'full name']}
        description={DESCRIPTION_UPDATE_FULL_NAME}
      />
    ),
  },
  {
    sortable: false,
    field: 'updateAvatarUrl_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'avatar']}
        description={DESCRIPTION_UPDATE_AVATAR_URL}
      />
    ),
  },
  {
    sortable: false,
    field: 'updateBio_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'bio']}
        description={DESCRIPTION_UPDATE_BIO}
      />
    ),
  },

  // ppl Moment
  {
    sortable: false,
    field: 'moment_ppl',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['moment', 'create']}
        description={DESCRIPTION_MOMENT}
      />
    ),
  },
  {
    sortable: false,
    field: 'moment_like_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'like']}
        description={DESCRIPTION_MOMENT_LIKE}
      />
    ),
  },
  {
    sortable: false,
    field: 'moment_comment_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'comment']}
        description={DESCRIPTION_MOMENT_COMMENT}
      />
    ),
  },

  // ppl Chatroom
  {
    sortable: false,
    field: 'chatroom_ppl',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'create']}
        description={DESCRIPTION_CHATROOM}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatroom_join_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'join']}
        description={DESCRIPTION_CHATROOM_JOIN}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatroom_leave_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'leave']}
        description={DESCRIPTION_CHATROOM_LEAVE}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatMessage_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'message']}
        description={DESCRIPTION_CHATROOM_MESSAGE}
      />
    ),
  },

  // ppl Friend
  {
    sortable: false,
    field: 'friend_request_ppl',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'request']}
        description={DESCRIPTION_FRIEND_REQUEST}
      />
    ),
  },
  {
    sortable: false,
    field: 'friend_accept_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'accept']}
        description={DESCRIPTION_FRIEND_ACCEPT}
      />
    ),
  },

  // ppl ContacsSyncBanner
  {
    sortable: false,
    field: 'contactsSyncBanner_ppl',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['contacts', 'sync', 'banner']}
        description={DESCRIPTION_CONTACTS_SYNC_BANNER}
      />
    ),
  },

  // ppl Phonebook
  {
    sortable: false,
    field: 'phonebook_ppl',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'sync']}
        description={DESCRIPTION_PHONEBOOK_SYNC}
      />
    ),
  },
  {
    sortable: false,
    field: 'sendSMS_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'send SMS']}
        description={DESCRIPTION_PHONEBOOK_SEND_SMS}
      />
    ),
  },
  // cnt update User
  {
    sortable: false,
    field: 'updateFullName_cnt',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'full name']}
        description={DESCRIPTION_UPDATE_FULL_NAME}
      />
    ),
  },
  {
    sortable: false,
    field: 'updateAvatarUrl_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'avatar']}
        description={DESCRIPTION_UPDATE_AVATAR_URL}
      />
    ),
  },
  {
    sortable: false,
    field: 'updateBio_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'bio']}
        description={DESCRIPTION_UPDATE_BIO}
      />
    ),
  },

  // cnt Moment
  {
    sortable: false,
    field: 'moment_cnt',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'create']}
        description={DESCRIPTION_MOMENT}
      />
    ),
  },
  {
    field: 'moment_like_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'like']}
        description={DESCRIPTION_MOMENT_LIKE}
      />
    ),
  },
  {
    sortable: false,
    field: 'moment_comment_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'comment']}
        description={DESCRIPTION_MOMENT_COMMENT}
      />
    ),
  },

  // cnt Chatroom
  {
    sortable: false,
    field: 'chatroom_cnt',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'create']}
        description={DESCRIPTION_CHATROOM}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatroom_join_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'join']}
        description={DESCRIPTION_CHATROOM_JOIN}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatroom_leave_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'leave']}
        description={DESCRIPTION_CHATROOM_LEAVE}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatMessage_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'message']}
        description={DESCRIPTION_CHATROOM_MESSAGE}
      />
    ),
  },

  // cnt Friend
  {
    sortable: false,
    field: 'friend_request_cnt',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'request']}
        description={DESCRIPTION_FRIEND_REQUEST}
      />
    ),
  },
  {
    sortable: false,
    field: 'friend_accept_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'accept']}
        description={DESCRIPTION_FRIEND_ACCEPT}
      />
    ),
  },

  // cnt ContacsSyncBanner
  {
    sortable: false,
    field: 'contactsSyncBanner_cnt',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['contacts', 'sync', 'banner']}
        description={DESCRIPTION_CONTACTS_SYNC_BANNER}
      />
    ),
  },

  // cnt Phonebook
  {
    sortable: false,
    field: 'phonebook_cnt',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'sync']}
        description={DESCRIPTION_PHONEBOOK_SYNC}
      />
    ),
  },
  {
    sortable: false,
    field: 'sendSMS_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'send SMS']}
        description={DESCRIPTION_PHONEBOOK_SEND_SMS}
      />
    ),
  },

  // Days After

  // ppl update User
  {
    sortable: false,
    field: 'updateFullName_daysAfter_ppl',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'full name']}
        description={DESCRIPTION_UPDATE_FULL_NAME}
      />
    ),
  },
  {
    sortable: false,
    field: 'updateAvatarUrl_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'avatar']}
        description={DESCRIPTION_UPDATE_AVATAR_URL}
      />
    ),
  },
  {
    sortable: false,
    field: 'updateBio_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'bio']}
        description={DESCRIPTION_UPDATE_BIO}
      />
    ),
  },

  // ppl Moment
  {
    sortable: false,
    field: 'moment_daysAfter_ppl',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'create']}
        description={DESCRIPTION_MOMENT}
      />
    ),
  },
  {
    sortable: false,
    field: 'moment_like_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'like']}
        description={DESCRIPTION_MOMENT_LIKE}
      />
    ),
  },
  {
    sortable: false,
    field: 'moment_comment_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'comment']}
        description={DESCRIPTION_MOMENT_COMMENT}
      />
    ),
  },

  // ppl Chatroom
  {
    sortable: false,
    field: 'chatroom_daysAfter_ppl',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'create']}
        description={DESCRIPTION_CHATROOM}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatroom_join_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'join']}
        description={DESCRIPTION_CHATROOM_JOIN}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatroom_leave_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'leave']}
        description={DESCRIPTION_CHATROOM_LEAVE}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatMessage_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'message']}
        description={DESCRIPTION_CHATROOM_MESSAGE}
      />
    ),
  },

  // ppl Friend
  {
    sortable: false,
    field: 'friend_request_daysAfter_ppl',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'request']}
        description={DESCRIPTION_FRIEND_REQUEST}
      />
    ),
  },
  {
    sortable: false,
    field: 'friend_accept_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'accept']}
        description={DESCRIPTION_FRIEND_ACCEPT}
      />
    ),
  },

  // ppl ContacsSyncBanner
  {
    sortable: false,
    field: 'contactsSyncBanner_daysAfter_ppl',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['contacts', 'sync', 'banner']}
        description={DESCRIPTION_CONTACTS_SYNC_BANNER}
      />
    ),
  },

  // ppl Phonebook
  {
    sortable: false,
    field: 'phonebook_daysAfter_ppl',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'sync']}
        description={DESCRIPTION_PHONEBOOK_SYNC}
      />
    ),
  },
  {
    sortable: false,
    field: 'sendSMS_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'send SMS']}
        description={DESCRIPTION_PHONEBOOK_SEND_SMS}
      />
    ),
  },

  // cnt update User
  {
    sortable: false,
    field: 'updateFullName_daysAfter_cnt',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'full name']}
        description={DESCRIPTION_UPDATE_FULL_NAME}
      />
    ),
  },
  {
    sortable: false,
    field: 'updateAvatarUrl_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'avatar']}
        description={DESCRIPTION_UPDATE_AVATAR_URL}
      />
    ),
  },
  {
    sortable: false,
    field: 'updateBio_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'bio']}
        description={DESCRIPTION_UPDATE_BIO}
      />
    ),
  },

  // cnt Moment
  {
    sortable: false,
    field: 'moment_daysAfter_cnt',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'create']}
        description={DESCRIPTION_MOMENT}
      />
    ),
  },
  {
    sortable: false,
    field: 'moment_like_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'like']}
        description={DESCRIPTION_MOMENT_LIKE}
      />
    ),
  },
  {
    sortable: false,
    field: 'moment_comment_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'comment']}
        description={DESCRIPTION_MOMENT_COMMENT}
      />
    ),
  },

  // cnt Chatroom
  {
    sortable: false,
    field: 'chatroom_daysAfter_cnt',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'create']}
        description={DESCRIPTION_CHATROOM}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatroom_join_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'join']}
        description={DESCRIPTION_CHATROOM_JOIN}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatroom_leave_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'leave']}
        description={DESCRIPTION_CHATROOM_LEAVE}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatMessage_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'message']}
        description={DESCRIPTION_CHATROOM_MESSAGE}
      />
    ),
  },

  // cnt Friend
  {
    sortable: false,
    field: 'friend_request_daysAfter_cnt',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'request']}
        description={DESCRIPTION_FRIEND_REQUEST}
      />
    ),
  },
  {
    sortable: false,
    field: 'friend_accept_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'accept']}
        description={DESCRIPTION_FRIEND_ACCEPT}
      />
    ),
  },

  // cnt ContacsSyncBanner
  {
    sortable: false,
    field: 'contactsSyncBanner_daysAfter_cnt',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['contacts', 'sync', 'banner']}
        description={DESCRIPTION_CONTACTS_SYNC_BANNER}
      />
    ),
  },

  // cnt Phonebook
  {
    sortable: false,
    field: 'phonebook_daysAfter_cnt',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'sync']}
        description={DESCRIPTION_PHONEBOOK_SYNC}
      />
    ),
  },
  {
    sortable: false,
    field: 'sendSMS_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'send SMS']}
        description={DESCRIPTION_PHONEBOOK_SEND_SMS}
      />
    ),
  },
]

const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: 'this_date',
    headerName: 'この日に発生した件数',
    headerClassName: 'bgDays',
    headerAlign: 'center',
    children: [
      {
        groupId: 'this_date_user',
        headerName: '',
        headerClassName: 'headerBorder',
        headerAlign: 'center',
        children: [
          { field: 'user_total' },
          { field: 'firstOpen_cnt' },
          { field: 'sendCodeButtonFirstTap_cnt' },
          { field: 'user_new' },
          { field: 'user_registration_rate' },
          { field: 'user_quit' },
          { field: 'dau' },
        ],
      },
      {
        groupId: 'this_date_ppl',
        headerName: 'User Unique',
        headerClassName: 'headerBorder',
        headerAlign: 'center',
        children: [
          { field: 'updateFullName_ppl' },
          { field: 'updateAvatarUrl_ppl' },
          { field: 'updateBio_ppl' },
          { field: 'moment_ppl' },
          { field: 'moment_like_ppl' },
          { field: 'moment_comment_ppl' },
          { field: 'chatroom_ppl' },
          { field: 'chatroom_join_ppl' },
          { field: 'chatroom_leave_ppl' },
          { field: 'chatMessage_ppl' },
          { field: 'friend_request_ppl' },
          { field: 'friend_accept_ppl' },
          { field: 'contactsSyncBanner_ppl' },
          { field: 'phonebook_ppl' },
          { field: 'sendSMS_ppl' },
        ],
      },
      {
        groupId: 'this_date_cnt',
        headerName: 'Total Event',
        headerClassName: 'headerBorder',
        headerAlign: 'center',
        children: [
          { field: 'updateFullName_cnt' },
          { field: 'updateAvatarUrl_cnt' },
          { field: 'updateBio_cnt' },
          { field: 'moment_cnt' },
          { field: 'moment_like_cnt' },
          { field: 'moment_comment_cnt' },
          { field: 'chatroom_cnt' },
          { field: 'chatroom_join_cnt' },
          { field: 'chatroom_leave_cnt' },
          { field: 'chatMessage_cnt' },
          { field: 'friend_request_cnt' },
          { field: 'friend_accept_cnt' },
          { field: 'contactsSyncBanner_cnt' },
          { field: 'phonebook_cnt' },
          { field: 'sendSMS_cnt' },
        ],
      },
    ],
  },
  {
    groupId: 'this_date_following_N_days',
    headerName: 'この日に登録したユーザーが、以降X日以内にアクションした件数',
    headerClassName: 'bgDaysAfter',
    headerAlign: 'center',
    children: [
      {
        groupId: 'this_date_following_N_days_ppl',
        headerName: 'User Unique',
        headerClassName: 'headerBorder',
        headerAlign: 'center',
        children: [
          { field: 'updateFullName_daysAfter_ppl' },
          { field: 'updateAvatarUrl_daysAfter_ppl' },
          { field: 'updateBio_daysAfter_ppl' },
          { field: 'moment_daysAfter_ppl' },
          { field: 'moment_like_daysAfter_ppl' },
          { field: 'moment_comment_daysAfter_ppl' },
          { field: 'chatroom_daysAfter_ppl' },
          { field: 'chatroom_join_daysAfter_ppl' },
          { field: 'chatroom_leave_daysAfter_ppl' },
          { field: 'chatMessage_daysAfter_ppl' },
          { field: 'friend_request_daysAfter_ppl' },
          { field: 'friend_accept_daysAfter_ppl' },
          { field: 'contactsSyncBanner_daysAfter_ppl' },
          { field: 'phonebook_daysAfter_ppl' },
          { field: 'sendSMS_daysAfter_ppl' },
        ],
      },
      {
        groupId: 'this_date_following_N_days_cnt',
        headerName: 'Total Event',
        headerClassName: 'headerBorder',
        headerAlign: 'center',
        children: [
          { field: 'updateFullName_daysAfter_cnt' },
          { field: 'updateAvatarUrl_daysAfter_cnt' },
          { field: 'updateBio_daysAfter_cnt' },
          { field: 'moment_daysAfter_cnt' },
          { field: 'moment_like_daysAfter_cnt' },
          { field: 'moment_comment_daysAfter_cnt' },
          { field: 'chatroom_daysAfter_cnt' },
          { field: 'chatroom_join_daysAfter_cnt' },
          { field: 'chatroom_leave_daysAfter_cnt' },
          { field: 'chatMessage_daysAfter_cnt' },
          { field: 'friend_request_daysAfter_cnt' },
          { field: 'friend_accept_daysAfter_cnt' },
          { field: 'contactsSyncBanner_daysAfter_cnt' },
          { field: 'phonebook_daysAfter_cnt' },
          { field: 'sendSMS_daysAfter_cnt' },
        ],
      },
    ],
  },
]

const initStatus = {
  columns: [],
  rows: [],
  loading: false,
}

const initFormData = {
  from: moment().subtract(14, 'day'),
  to: moment().utcOffset(TIMEZONES[0].offset),
  timezone: TIMEZONES[0].name,
  storeCountryCode: 'ALL',
  daysAfter: 7,
}

function KpiDB() {
  const [status, setStatus] = useState(initStatus)
  const updateStatus = (newStatus: object) => {
    setStatus((old) => ({ ...old, ...newStatus }))
  }

  return (
    <DataGrid
      loading={status.loading}
      columns={status.columns}
      rows={status.rows}
      components={{
        Toolbar: SearchForm,
      }}
      componentsProps={{
        toolbar: {
          updateStatus,
        },
      }}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
      disableColumnFilter
      disableColumnMenu
      hideFooter
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          overflowWrap: 'break-word',
          wordBreak: 'break-all',
          lineHeight: '1rem',
          whiteSpace: 'normal',
        },
        '.headerBorder': {
          borderLeft: 'solid 1px rgba(0, 0, 0, 1) !important',
          // borderRight: 'solid 1px rgba(0, 0, 0, 1) !important',
        },
        '.borderLeft': {
          borderLeft: 'solid 1px rgba(0, 0, 0, 1) !important',
        },
        '.borderRight': {
          borderRight: 'solid 1px rgba(0, 0, 0, 1) !important',
        },
        '.bgDays': {
          backgroundColor: '#7fffff',
        },
        '.bgDaysPpl': {
          backgroundColor: '#7fbfff',
        },
        '.bgDaysCnt': {
          backgroundColor: '#7f7fff',
        },
        '.bgDaysAfter': {
          backgroundColor: '#7fff7f',
        },
        '.bgDaysAfterPpl': {
          backgroundColor: '#bfff7f',
        },
        '.bgDaysAfterCnt': {
          backgroundColor: '#7fffbf',
        },
      }}
      showColumnRightBorder
      showCellRightBorder
      experimentalFeatures={{ columnGrouping: true }}
      columnGroupingModel={columnGroupingModel}
    />
  )
}

function SearchForm(props: { updateStatus: (obj: object) => void }) {
  const { updateStatus } = props
  const [formData, setFormData] = useState<FormData>(initFormData)
  const updateFormData = (newFormData: object) => {
    setFormData((old) => ({ ...old, ...newFormData }))
  }
  const onSubmit = async () => {
    updateStatus({ loading: true })

    const { columns, rows } = await fetchData({ formData })

    updateStatus({
      columns,
      rows,
      loading: false,
    })
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box padding={1}>
          <Stack direction="row" spacing={2}>
            <DatePicker
              label="from"
              value={formData.from}
              onChange={(value) => updateFormData({ from: value })}
              format="YYYY/MM/DD"
              slotProps={{
                textField: {
                  sx: { maxWidth: 170 },
                },
              }}
            />

            <DatePicker
              label="to"
              value={formData.to}
              onChange={(value) => updateFormData({ to: value })}
              format="YYYY/MM/DD"
              slotProps={{
                textField: {
                  sx: { maxWidth: 170 },
                },
              }}
            />

            <FormControl sx={{ minWidth: 140 }}>
              <InputLabel id="kpi-country-select-label">
                Country
              </InputLabel>
              <Select
                labelId="kpi-country-select-label"
                id="kpi-country-select"
                value={formData.storeCountryCode}
                label="Country"
                onChange={(event) => updateFormData({ storeCountryCode: event.target.value })}
              >
                <MenuItem value="ALL">ALL</MenuItem>
                <MenuItem value="USA_CAN">USA & CAN</MenuItem>
                <MenuItem value="USA">USA</MenuItem>
                <MenuItem value="CAN">CAN</MenuItem>
                <MenuItem value="JPN">JPN</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 100 }}>
              <InputLabel id="kpi-timezone-select-label">
                timezone
              </InputLabel>
              <Select
                labelId="kpi-timezone-select-label"
                id="kpi-timezone-select"
                value={formData.timezone}
                label="timezone"
                onChange={(event) => updateFormData({ timezone: event.target.value })}
              >
                <MenuItem value="UTC">UTC</MenuItem>
                <MenuItem value="America/Los_Angeles">PT</MenuItem>
                <MenuItem value="Asia/Tokyo">JST</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 100 }}>
              <InputLabel id="kpi-daysAfter-select-label">
                days after
              </InputLabel>
              <Select
                labelId="kpi-daysAfter-select-label"
                id="kpi-daysAfter-select"
                value={formData.daysAfter}
                label="days after"
                onChange={(event) => updateFormData({ daysAfter: event.target.value })}
              >
                <MenuItem value="7">7</MenuItem>
                <MenuItem value="14">14</MenuItem>
              </Select>
            </FormControl>
          </Stack>

          <CardActions>
            <Button
              variant="outlined"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </CardActions>

          <GridToolbarContainer>
            <GridToolbarExport
              csvOptions={{
                fileName: 'kpi',
              }}
              printOptions={{
                disableToolbarButton: true,
              }}
            />
          </GridToolbarContainer>
        </Box>
      </LocalizationProvider>
    </>
  )
}

function Cell(props: { value: number | { [key: string]: number } }) {
  const { value } = props

  if (typeof value === 'object') {
    const { countUser, countEvent } = value

    return (
      <Typography variant="body2" align="right">
        {countUser}
        <Typography variant="caption"> ppl</Typography>
        <br />
        {countEvent}
        <Typography variant="caption"> cnt</Typography>
      </Typography>
    )
  }

  return (
    <Typography variant="body2">
      {(value || value === 0) ? numberFormat(value) : '0'}
    </Typography>
  )
}

async function fetchData({
  formData,
}: {
  formData: FormData
}) {
  const columns: GridColumns = []

  formData.from.toJSON = () => formData.from.format('YYYYMMDD')
  formData.to.toJSON = () => formData.to.format('YYYYMMDD')

  const rows = await fetchAdmin({
    path: 'analytics/kpi/db',
    method: 'GET',
    queries: {
      form: JSON.stringify(formData),
    },
  })
    .then((res) => res.json())
    .catch(() => {})

  initColumns.forEach((col) => {
    columns.push(initGridColumn(col))
  })

  return {
    columns,
    rows,
  }
}

function initGridColumn(column: GridEnrichedColDef): GridEnrichedColDef {
  return {
    type: 'number',
    headerAlign: 'center',
    renderCell: column.valueGetter
      ? undefined
      : ({ value }) => <Cell value={value} />,
    hideSortIcons: true,
    ...column,
  }
}

function MultilineHeader(props: { names: string[], description: string }) {
  const { names, description } = props
  let lineNo = 0

  return (
    <Tooltip title={description}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ lineHeight: '1rem' }}
      >
        {
          names.map((name) => (
            <Grid item key={++lineNo}>{name}</Grid>
          ))
        }
      </Grid>
    </Tooltip>
  )
}

export default KpiDB
