import { Box } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { useEffect } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import AdminUsers from './components/adminUser/AdminUsers'
import AdminUserRoles from './components/adminUserRole/AdminUserRoles'
import AnalyticsCampaign from './components/analytics/Campaign'
import DauDetails from './components/analytics/DauDetail'
import KpiDB from './components/analytics/KpiDB'
import KpiDBPersonalize from './components/analytics/KpiDBPersonalize'
import KpiDBPersonalizeDau from './components/analytics/KpiDBPersonalizeDau'
import AnalyticsMoment from './components/analytics/MomentRetention'
import Layout from './components/app/Layout'
import CampaignDetails from './components/campaign/CampaignDetails'
import Campaigns from './components/campaign/Campaigns'
import GiftCodeDetails from './components/campaign/GiftCodeDetails'
import GiftCodes from './components/campaign/GiftCodes'
import Pages from './components/campaign/Pages'
import RewardDetails from './components/campaign/RewardDetails'
import Rewards from './components/campaign/Rewards'
import Summary from './components/campaign/Summary'
import WeeklyLikeMomentDetailsRouter from './components/campaign/WeeklyLikeMomentDetailsRouter'
import WeeklyLikeMoments from './components/campaign/WeeklyLikeMoments'
import ChatDetails from './components/chat/ChatDetails'
import Chat from './components/chat/Chats'
import Login from './components/common/Login'
import TextFilter from './components/contentModeration/TextFilter'
import Dashboard from './components/dashboard/Dashboard'
import DialogReportDetails from './components/dialog-report/DialogReportDetails'
import DialogReports from './components/dialog-report/DialogReports'
import DialogDetails from './components/v2/dialogs/dialogDetails/DialogDetails'
import Dialogs from './components/v2/dialogs/Dialogs'
import Explore from './components/v2/dialogs/Explore'
import Featured from './components/dialog/Featured'
import JobDetails from './components/job/JobDetails'
import Jobs from './components/job/Jobs'
import Maintenance from './components/maintenance/Maintenance'
import MessageReportDetails from './components/message-reports/MessageReportDetails'
import MessageReports from './components/message-reports/MessageReports'
import MomentReportDetails from './components/moment-reports/MomentReportDetails'
import MomentReports from './components/moment-reports/MomentReports'
import MomentDetailsRouter from './components/moment/MomentDetailsRouter'
import Moments from './components/moment/Moments'
import Revisions from './components/revisions/Revisions'
import UploadImages from './components/uploadImages/UploadImages'
import UserReportDetails from './components/user-reports/UserReportDetails'
import UserReports from './components/user-reports/UserReports'
import UserDetails from './components/v2/user/details/UserDetails'
import Users from './components/v2/user/Users'
import { RootProvider } from './provider/RootProvider'
import { getCookie } from './services/cookieUtils'

function App() {
  const navigate = useNavigate()
  const { userName, role: userRole } = JSON.parse(decodeURIComponent(getCookie('adminUser') || '{}'))
  const loggedIn = userName && userRole
  const navigateTo = window.location.pathname

  useEffect(() => {
    if (!loggedIn && navigateTo !== '/login' && navigateTo !== '/') {
      navigate(`/login?redirect=${encodeURIComponent(navigateTo)}`)
    }
  }, [loggedIn, navigate, navigateTo])

  return (
    <RootProvider>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        <Routes>
          {
            loggedIn
              ? (
                <Route element={<Layout userName={userName} userRole={userRole} />}>
                  <Route path="/users">
                    <Route path="" element={<Users />} />
                    <Route path=":quickbloxUserId" element={<UserDetails />} />
                  </Route>
                  <Route path="/dialogs">
                    <Route path="" element={<Dialogs />} />
                    <Route path=":quickbloxDialogId" element={<DialogDetails />} />
                    <Route path="explore" element={<Explore />} />
                    <Route path="explore/:quickbloxDialogId" element={<DialogDetails />} />
                    <Route path="featured" element={<Featured />} />
                  </Route>
                  <Route path="/moments">
                    <Route path="" element={<Moments />} />
                    <Route path=":identifier" element={<MomentDetailsRouter />} />
                  </Route>
                  <Route path="/reports">
                    <Route path="users">
                      <Route path="" element={<UserReports />} />
                      <Route path=":identifier" element={<UserReportDetails />} />
                    </Route>
                    <Route path="moments">
                      <Route path="" element={<MomentReports />} />
                      <Route path=":identifier" element={<MomentReportDetails />} />
                    </Route>
                    <Route path="messages">
                      <Route path="" element={<MessageReports />} />
                      <Route path=":identifier" element={<MessageReportDetails />} />
                    </Route>
                    <Route path="chatMessages">
                      <Route path="" element={<Chat />} />
                      <Route path=":identifier" element={<ChatDetails />} />
                    </Route>
                    <Route path="dialogs">
                      <Route path="" element={<DialogReports />} />
                      <Route path=":identifier" element={<DialogReportDetails />} />
                    </Route>
                  </Route>
                  <Route path="/maintenance" element={<Maintenance />} />
                  <Route path="/revisions" element={<Revisions />} />
                  <Route path="/analytics">
                    <Route path="kpi/db" element={<KpiDB />} />
                    <Route path="kpi/db/personalize" element={<KpiDBPersonalize />} />
                    <Route path="kpi/db/personalize/dau" element={<KpiDBPersonalizeDau />} />
                    <Route path="kpi/db/personalize/dau/users" element={<DauDetails />} />
                    <Route path="campaign" element={<AnalyticsCampaign />} />
                    <Route path="moment" element={<AnalyticsMoment />} />
                  </Route>
                  <Route path="/admin">
                    <Route path="users" element={<AdminUsers />} />
                    <Route path="userRoles" element={<AdminUserRoles />} />
                  </Route>
                  <Route path="/contentModeration">
                    <Route path="/contentModeration/textFilters" element={<TextFilter />} />
                  </Route>
                  <Route path="/uploadImages" element={<UploadImages />} />
                  <Route path="/campaign">
                    <Route path="" element={<Campaigns />} />
                    <Route path="create" element={<CampaignDetails />} />
                    <Route path=":id" element={<CampaignDetails />} />
                    <Route path="giftCodes" element={<GiftCodes />} />
                    <Route path="giftCodes/:id" element={<GiftCodeDetails />} />
                    <Route path="pages" element={<Pages />} />
                    <Route path="summary" element={<Summary />} />
                    <Route path="weeklyLikeMoments" element={<WeeklyLikeMoments />} />
                    <Route path="weeklyLikeMoments/:weeklyLikeMomentId" element={<WeeklyLikeMomentDetailsRouter />} />
                    <Route path="rewards">
                      <Route path="" element={<Rewards />} />
                      <Route path="create" element={<RewardDetails />} />
                      <Route path=":id" element={<RewardDetails />} />
                    </Route>
                  </Route>
                  <Route path="/jobs">
                    <Route path="" element={<Jobs />} />
                    <Route path=":identifier" element={<JobDetails />} />
                  </Route>
                  <Route path="/dashboard">
                    <Route path="" element={<Dashboard />} />
                  </Route>
                  <Route path="/*" element={<Redirect path="/dashboard" />} />
                </Route>
              )
              : (
                <>
                  <Route path="/login" element={<Login />} />
                  <Route path="/*" element={<Redirect path="/login" />} />
                </>
              )
          }
        </Routes>
      </Box>
    </RootProvider>
  )
}

function Redirect(props: { path: string }) {
  const { path } = props
  const location = useLocation()

  return (
    <Navigate to={path} state={{ from: location }} replace />
  )
}

export default App
