import {
  Typography,
  Grid,
  Tooltip,
} from '@mui/material'
import {
  GridColumns,
  GridColumnGroupingModel,
} from '@mui/x-data-grid'
import moment from 'moment'
import { numberFormat } from '../services/number'
import { TIMEZONES } from '../constants'

const DESCRIPTION_UPDATE_FULL_NAME = 'フルネームを更新した数'
const DESCRIPTION_UPDATE_AVATAR_URL = 'アバター画像を更新した数'
const DESCRIPTION_UPDATE_BIO = 'Bioを更新した数'
const DESCRIPTION_MOMENT = 'Momentの投稿数'
const DESCRIPTION_MOMENT_LIKE = 'MomentのLike数（1人のユーザーが1~10の間でLikeしている）'
const DESCRIPTION_MOMENT_COMMENT = 'Momentのコメント数'
const DESCRIPTION_CHATROOM = 'チャットルームの作成数（Private、Peekable、Open）'
const DESCRIPTION_CHATROOM_JOIN = 'チャットルームへの参加数（Private、Peekable、Open）'
const DESCRIPTION_CHATROOM_LEAVE = 'チャットルームからの退出数（Private、Peekable、Open）'
const DESCRIPTION_CHATROOM_MESSAGE = 'チャットルームでのメッセージ送信数（Private、Peekable、Open）'
const DESCRIPTION_FRIEND_REQUEST = 'フレンドリクエストの送信数'
const DESCRIPTION_FRIEND_ACCEPT = 'フレンドリクエストを承認した数'
const DESCRIPTION_CONTACTS_SYNC_BANNER = '連絡先同期バナーをクリックした数'
const DESCRIPTION_PHONEBOOK_SYNC = '電話帳を同期した数'
const DESCRIPTION_PHONEBOOK_SEND_SMS = 'Bulk InviteでのSMS送信数'

export const initColumns: GridColumns = [
  {
    sortable: false,
    field: 'ymd',
    align: 'center',
    valueGetter: ({ value }) => {
      const date = moment(value, 'YYYYMMDD')
      return date.isValid() ? date.format('YYYY/MM/DD') : ''
    },
    renderCell: ({ value }) => (
      <Typography variant="body2">
        {value}
      </Typography>
    ),
  },
  {
    sortable: false,
    field: 'days',
    align: 'center',
    width: 60,
    valueGetter: ({ row }) => moment(row.ymd.toString()).format('ddd'),
    renderCell: ({ row }) => {
      const days = moment(row.ymd.toString())
      const isWeekEnd = parseInt(days.format('E')) >= 6

      return (
        <Typography variant="body2" color={isWeekEnd ? 'red' : undefined}>
          {days.format('ddd')}
        </Typography>
      )
    },
  },

  // User
  {
    sortable: false,
    field: 'user_total',
    headerClassName: 'borderLeft',
    cellClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['user total']}
        description="電話番号登録済みの合計ユーザー数"
      />
    ),
  },
  {
    sortable: false,
    field: 'firstOpen_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['first open']}
        description="インストール後はじめてアプリを開いた人数"
      />
    ),
    renderCell: (params: any) => {
      const storeCountryCode = params.row.firstOpen_storeCountryCode
      return (
        <Tooltip title={storeCountryCode}>
          <span>{params.value}</span>
        </Tooltip>
      )
    },
  },
  {
    sortable: false,
    field: 'sendCodeButtonFirstTap_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['tap', 'send button']}
        description="電話番号を入力してボタンをタップした人数"
      />
    ),
    renderCell: (params: any) => {
      const storeCountryCode = params.row.firstOpen_storeCountryCode
      return (
        <Tooltip title={storeCountryCode}>
          <span>{params.value}</span>
        </Tooltip>
      )
    },
  },
  {
    sortable: false,
    field: 'user_new',
    renderHeader: () => (
      <MultilineHeader
        names={['user new']}
        description="電話番号認証を完了した人数（ユーザー登録完了）"
      />
    ),
  },
  {
    sortable: false,
    field: 'user_registration_rate',
    headerName: 'user_registration_rate（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['user', 'registration', '%']}
        description="user new / first open の数値で電話番号認証を完了した割合（ユーザー登録完了）"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'user_quit',
    renderHeader: () => (
      <MultilineHeader
        names={['user quit']}
        description="解約したユーザー数"
      />
    ),
    renderCell: ({ value }) => {
      const exist = !!parseInt(value)

      return (
        <Typography variant="body2" color={exist ? 'red' : undefined}>
          {exist ? '-' : null}
          {numberFormat(value)}
        </Typography>
      )
    },
  },
  {
    sortable: false,
    field: 'dau',
    headerName: 'Daily Active Users',
    headerClassName: 'borderRight',
    renderHeader: () => (
      <MultilineHeader
        names={['dau']}
        description="Daily Active User"
      />
    ),
    renderCell: ({ value }) => `${value}`,
  },
  // ppl update User
  {
    sortable: false,
    field: 'updateFullName_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'full name']}
        description={DESCRIPTION_UPDATE_FULL_NAME}
      />
    ),
  },
  {
    sortable: false,
    field: 'updateFullName_ratio',
    headerName: 'updateFullName_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'full name', '（％）']}
        description="dauユーザーとフルネームを更新したユーザーの比率を示し、プロフィール更新への新規ユーザーの関与"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'updateAvatarUrl_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'avatar']}
        description={DESCRIPTION_UPDATE_AVATAR_URL}
      />
    ),
  },
  {
    sortable: false,
    field: 'updateAvatarUrl_ratio',
    headerName: 'updateAvatarUrl_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'avatar', '（％）']}
        description="dauユーザーとアバターURLを更新したユーザーの比率を示し、アバターURL更新への新規ユーザーの関与"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'updateBio_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'bio']}
        description={DESCRIPTION_UPDATE_BIO}
      />
    ),
  },
  {
    sortable: false,
    field: 'updateBio_ratio',
    headerName: 'updateBio_ratio（％）',
    headerClassName: 'borderRight',
    cellClassName: 'borderRight',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'bio', '（％）']}
        description="dauユーザーとBioを更新したユーザーの比率を示し、Bio更新への新規ユーザーの関与"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  // ppl Moment
  {
    sortable: false,
    field: 'moment_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['moment', 'create']}
        description={DESCRIPTION_MOMENT}
      />
    ),
  },
  {
    sortable: false,
    field: 'moment_rate',
    headerName: 'moment_create_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['moment', 'create', '（％）']}
        description="dauユーザーとモーメントにアップしたユーザーの比率を示し、アップ機能への新規ユーザーの関与"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'moment_like_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'like']}
        description={DESCRIPTION_MOMENT_LIKE}
      />
    ),
  },
  {
    sortable: false,
    field: 'moment_like_ratio',
    headerName: 'moment_like_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['moment', 'like', '（％）']}
        description="dauユーザーとモーメントにライクしたユーザーの比率を示し、ライク機能への新規ユーザーの関与"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'moment_comment_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'comment']}
        description={DESCRIPTION_MOMENT_COMMENT}
      />
    ),
  },
  {
    sortable: false,
    field: 'moment_comment_ratio',
    headerName: 'moment_comment_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['moment', 'comment', '（％）']}
        description="dauユーザーとモーメントにコメントしたユーザーの比率を示し、コメント機能への新規ユーザーの関与"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },

  // ppl Chatroom
  {
    sortable: false,
    field: 'chatroom_ppl',
    headerClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'create']}
        description={DESCRIPTION_CHATROOM}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatroom_ratio',
    headerName: 'chatroom_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', '（％）']}
        description="dauユーザーとチャットルームに参加したユーザーの比率を示し、チャットルームへの新規ユーザーの関与"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'chatroom_join_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'join']}
        description={DESCRIPTION_CHATROOM_JOIN}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatroom_join_ratio',
    headerName: 'chatroom_join_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'join', '（％）']}
        description="dauユーザーとチャットルームに参加した回数の比率を示し、チャットルーム参加への新規ユーザーの関与"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'chatroom_leave_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'leave']}
        description={DESCRIPTION_CHATROOM_LEAVE}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatroom_leave_ratio',
    headerName: 'chatroom_leave_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'leave', '（％）']}
        description="dauユーザーとチャットルームを退出したユーザーの比率を示し、チャットルーム退出への新規ユーザーの関与"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'chatMessage_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'message']}
        description={DESCRIPTION_CHATROOM_MESSAGE}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatMessage_ratio',
    headerClassName: 'borderRight',
    headerName: 'chatMessage_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'message', '（％）']}
        description="dauユーザーとチャットメッセージを送信したユーザーの比率を示し、メッセージ送信への新規ユーザーの関与"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },

  // ppl Friend
  {
    sortable: false,
    field: 'friend_request_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'request']}
        description={DESCRIPTION_FRIEND_REQUEST}
      />
    ),
  },
  {
    sortable: false,
    field: 'friend_request_ratio',
    headerName: 'friend_request_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'request', '（％）']}
        description="dauユーザーとフレンドリクエストを送信したユーザーの比率を示し、フレンドリクエスト送信への新規ユーザーの関与"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'friend_accept_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'accept']}
        description={DESCRIPTION_FRIEND_ACCEPT}
      />
    ),
  },
  {
    sortable: false,
    field: 'friend_accept_ratio',
    headerName: 'friend_accept_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'accept', '（％）']}
        description="dauユーザーとフレンドリクエストを承認したユーザーの比率を示し、フレンド承認への新規ユーザーの関与"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },

  // ppl ContacsSyncBanner
  {
    sortable: false,
    field: 'contactsSyncBanner_ppl',
    headerClassName: 'borderLeft',
    renderHeader: () => (
      <MultilineHeader
        names={['contacts', 'sync', 'banner']}
        description={DESCRIPTION_CONTACTS_SYNC_BANNER}
      />
    ),
  },
  {
    sortable: false,
    field: 'contactsSyncBanner_ratio',
    headerName: 'contactsSyncBanner_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['contacts', 'sync', 'banner', '（％）']}
        description="dauユーザーと連絡先同期バナーを表示したユーザーの比率を示し、連絡先同期機能への新規ユーザーの関与"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },

  // ppl Phonebook
  {
    sortable: false,
    field: 'phonebook_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'sync']}
        description={DESCRIPTION_PHONEBOOK_SYNC}
      />
    ),
  },
  {
    sortable: false,
    field: 'phonebook_ratio',
    headerName: 'phonebook_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'sync', '（％）']}
        description="dauユーザーと電話帳を使用したユーザーの比率を示し、電話帳機能への新規ユーザーの関与"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'sendSMS_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'send SMS']}
        description={DESCRIPTION_PHONEBOOK_SEND_SMS}
      />
    ),
  },
  {
    sortable: false,
    field: 'sendSMS_ratio',
    headerClassName: 'borderRight',
    cellClassName: 'borderRight',
    headerName: 'sendSMS_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'send SMS', '（％）']}
        description="dauユーザーとSMSを送信したユーザーの比率を示し、SMS送信機能への新規ユーザーの関与"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  // cnt update User
  {
    sortable: false,
    field: 'updateFullName_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'full name']}
        description={DESCRIPTION_UPDATE_FULL_NAME}
      />
    ),
  },
  {
    sortable: false,
    field: 'updateAvatarUrl_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'avatar']}
        description={DESCRIPTION_UPDATE_AVATAR_URL}
      />
    ),
  },
  {
    sortable: false,
    field: 'updateBio_cnt',
    headerClassName: 'borderRight',
    cellClassName: 'borderRight',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'bio']}
        description={DESCRIPTION_UPDATE_BIO}
      />
    ),
  },

  // cnt Moment
  {
    sortable: false,
    field: 'moment_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'create']}
        description={DESCRIPTION_MOMENT}
      />
    ),
  },
  {
    sortable: false,
    field: 'moment_like_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'like']}
        description={DESCRIPTION_MOMENT_LIKE}
      />
    ),
  },
  {
    sortable: false,
    field: 'moment_comment_cnt',
    headerClassName: 'borderRight',
    cellClassName: 'borderRight',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'comment']}
        description={DESCRIPTION_MOMENT_COMMENT}
      />
    ),
  },

  // cnt Chatroom
  {
    sortable: false,
    field: 'chatroom_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'create']}
        description={DESCRIPTION_CHATROOM}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatroom_join_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'join']}
        description={DESCRIPTION_CHATROOM_JOIN}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatroom_leave_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'leave']}
        description={DESCRIPTION_CHATROOM_LEAVE}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatMessage_cnt',
    headerClassName: 'borderRight',
    cellClassName: 'borderRight',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'message']}
        description={DESCRIPTION_CHATROOM_MESSAGE}
      />
    ),
  },

  // cnt Friend
  {
    sortable: false,
    field: 'friend_request_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'request']}
        description={DESCRIPTION_FRIEND_REQUEST}
      />
    ),
  },
  {
    sortable: false,
    field: 'friend_accept_cnt',
    headerClassName: 'borderRight',
    cellClassName: 'borderRight',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'accept']}
        description={DESCRIPTION_FRIEND_ACCEPT}
      />
    ),
  },

  // cnt ContacsSyncBanner
  {
    sortable: false,
    field: 'contactsSyncBanner_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['contacts', 'sync', 'banner']}
        description={DESCRIPTION_CONTACTS_SYNC_BANNER}
      />
    ),
  },

  // cnt Phonebook
  {
    sortable: false,
    field: 'phonebook_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'sync']}
        description={DESCRIPTION_PHONEBOOK_SYNC}
      />
    ),
  },
  {
    sortable: false,
    field: 'sendSMS_cnt',
    headerClassName: 'borderRight',
    cellClassName: 'borderRight',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'send SMS']}
        description={DESCRIPTION_PHONEBOOK_SEND_SMS}
      />
    ),
  },

  // Days After

  // ppl update User
  {
    sortable: false,
    field: 'updateFullName_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'full name']}
        description={DESCRIPTION_UPDATE_FULL_NAME}
      />
    ),
  },
  {
    sortable: false,
    field: 'updateFullName_daysAfter_ratio',
    headerName: 'updateFullName_daysAfter_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'full name', 'daysAfter', '（％）']}
        description="新規ユーザー数と数日後にフルネームを更新したユーザー数の比率"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'updateAvatarUrl_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'avatar']}
        description={DESCRIPTION_UPDATE_AVATAR_URL}
      />
    ),
  },
  {
    sortable: false,
    field: 'updateAvatarUrl_daysAfter_ratio',
    headerName: 'updateAvatarUrl_daysAfter_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'avatar', 'daysAfter', '（％）']}
        description="新規ユーザー数と数日後にアバター画像を更新したユーザー数の比率"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'updateBio_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'bio']}
        description={DESCRIPTION_UPDATE_BIO}
      />
    ),
  },
  {
    sortable: false,
    field: 'updateBio_daysAfter_ratio',
    headerName: 'updateBio_daysAfter_ratio（％）',
    headerClassName: 'borderRight',
    cellClassName: 'borderRight',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'bio', 'daysAfter', '（％）']}
        description="新規ユーザー数と数日後に自己紹介を更新したユーザー数の比率"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },

  // ppl Moment
  {
    sortable: false,
    field: 'moment_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'create']}
        description={DESCRIPTION_MOMENT}
      />
    ),
  },
  {
    sortable: false,
    field: 'moment_daysAfter_ratio',
    headerName: 'moment_daysAfter_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'ppl', 'daysAfter', '（％）']}
        description="新規ユーザー数と数日後にモーメントしたユーザー数の比率"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'moment_like_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'like']}
        description={DESCRIPTION_MOMENT_LIKE}
      />
    ),
  },
  {
    sortable: false,
    field: 'moment_like_daysAfter_ratio',
    headerName: 'moment_like_daysAfter_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'like', 'daysAfter', '（％）']}
        description="新規ユーザー数と数日後にモーメントにライクしたユーザー数の比率"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'moment_comment_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'comment']}
        description={DESCRIPTION_MOMENT_COMMENT}
      />
    ),
  },
  {
    sortable: false,
    field: 'moment_comment_daysAfter_ratio',
    headerName: 'moment_comment_daysAfter_ratio（％）',
    headerClassName: 'borderRight',
    cellClassName: 'borderRight',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'comment', 'daysAfter', '（％）']}
        description="新規ユーザー数と数日後にモーメントにコメントしたユーザー数の比率"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },

  // ppl Chatroom
  {
    sortable: false,
    field: 'chatroom_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'create']}
        description={DESCRIPTION_CHATROOM}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatroom_daysAfter_ratio',
    headerName: 'chatroom_daysAfter_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'create', 'daysAfter', '（％）']}
        description="新規ユーザー数と数日後にチャットルームに参加したユーザー数の比率"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'chatroom_join_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'join']}
        description={DESCRIPTION_CHATROOM_JOIN}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatroom_join_daysAfter_ratio',
    headerName: 'chatroom_join_daysAfter_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'join', 'daysAfter', '（％）']}
        description="新規ユーザー数と数日後にチャットルームに参加した回数の比率"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'chatroom_leave_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'leave']}
        description={DESCRIPTION_CHATROOM_LEAVE}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatroom_leave_daysAfter_ratio',
    headerName: 'chatroom_leave_daysAfter_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'leave', 'daysAfter', '（％）']}
        description="新規ユーザー数と数日後にチャットルームを退出したユーザー数の比率"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'chatMessage_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'message']}
        description={DESCRIPTION_CHATROOM_MESSAGE}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatMessage_daysAfter_ratio',
    headerClassName: 'borderRight',
    cellClassName: 'borderRight',
    headerName: 'chatMessage_daysAfter_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['chat', 'message', 'daysAfter', '（％）']}
        description="新規ユーザー数と数日後にチャットメッセージを送信したユーザー数の比率"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },

  // ppl Friend
  {
    sortable: false,
    field: 'friend_request_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'request']}
        description={DESCRIPTION_FRIEND_REQUEST}
      />
    ),
  },
  {
    sortable: false,
    field: 'friend_request_daysAfter_ratio',
    headerName: 'friend_request_daysAfter_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'request', 'daysAfter', '（％）']}
        description="新規ユーザー数と数日後にチャットメッセージを送信したユーザー数の比率"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'friend_accept_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'accept']}
        description={DESCRIPTION_FRIEND_ACCEPT}
      />
    ),
  },
  {
    sortable: false,
    field: 'friend_accept_daysAfter_ratio',
    headerClassName: 'borderRight',
    cellClassName: 'borderRight',
    headerName: 'friend_accept_daysAfter_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'accept', 'daysAfter', '（％）']}
        description="新規ユーザー数と数日後にフレンドリクエストを承認したユーザー数の比率"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },

  // ppl ContacsSyncBanner
  {
    sortable: false,
    field: 'contactsSyncBanner_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['contacts', 'sync', 'banner']}
        description={DESCRIPTION_CONTACTS_SYNC_BANNER}
      />
    ),
  },
  {
    sortable: false,
    field: 'contactsSyncBanner_daysAfter_ratio',
    headerName: 'contactsSyncBanner_daysAfter_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['contacts', 'sync', 'banner', 'daysAfter', '（％）']}
        description="新規ユーザー数と数日後に連絡先同期バナーを表示したユーザー数の比率"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },

  // ppl Phonebook
  {
    sortable: false,
    field: 'phonebook_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'sync']}
        description={DESCRIPTION_PHONEBOOK_SYNC}
      />
    ),
  },
  {
    sortable: false,
    field: 'phonebook_daysAfter_ratio',
    headerName: 'phonebook_daysAfter_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'sync', 'daysAfter', '（％）']}
        description="新規ユーザー数と数日後に電話帳を使用したユーザー数の比率"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },
  {
    sortable: false,
    field: 'sendSMS_daysAfter_ppl',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'send SMS']}
        description={DESCRIPTION_PHONEBOOK_SEND_SMS}
      />
    ),
  },
  {
    sortable: false,
    field: 'sendSMS_daysAfter_ratio',
    headerClassName: 'borderRight',
    cellClassName: 'borderRight',
    headerName: 'sendSMS_daysAfter_ratio（％）',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'send SMS', 'daysAfter', '（％）']}
        description="新規ユーザー数と数日後にSMSを送信したユーザー数の比率"
      />
    ),
    renderCell: ({ value }) => `${value}%`,
  },

  // cnt update User
  {
    sortable: false,
    field: 'updateFullName_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'full name']}
        description={DESCRIPTION_UPDATE_FULL_NAME}
      />
    ),
  },
  {
    sortable: false,
    field: 'updateAvatarUrl_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'avatar']}
        description={DESCRIPTION_UPDATE_AVATAR_URL}
      />
    ),
  },
  {
    sortable: false,
    field: 'updateBio_daysAfter_cnt',
    headerClassName: 'borderRight',
    cellClassName: 'borderRight',
    renderHeader: () => (
      <MultilineHeader
        names={['update', 'bio']}
        description={DESCRIPTION_UPDATE_BIO}
      />
    ),
  },

  // cnt Moment
  {
    sortable: false,
    field: 'moment_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'create']}
        description={DESCRIPTION_MOMENT}
      />
    ),
  },
  {
    sortable: false,
    field: 'moment_like_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'like']}
        description={DESCRIPTION_MOMENT_LIKE}
      />
    ),
  },
  {
    sortable: false,
    field: 'moment_comment_daysAfter_cnt',
    headerClassName: 'borderRight',
    cellClassName: 'borderRight',
    renderHeader: () => (
      <MultilineHeader
        names={['momment', 'comment']}
        description={DESCRIPTION_MOMENT_COMMENT}
      />
    ),
  },

  // cnt Chatroom
  {
    sortable: false,
    field: 'chatroom_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'create']}
        description={DESCRIPTION_CHATROOM}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatroom_join_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'join']}
        description={DESCRIPTION_CHATROOM_JOIN}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatroom_leave_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'leave']}
        description={DESCRIPTION_CHATROOM_LEAVE}
      />
    ),
  },
  {
    sortable: false,
    field: 'chatMessage_daysAfter_cnt',
    headerClassName: 'borderRight',
    cellClassName: 'borderRight',
    renderHeader: () => (
      <MultilineHeader
        names={['chatroom', 'message']}
        description={DESCRIPTION_CHATROOM_MESSAGE}
      />
    ),
  },

  // cnt Friend
  {
    sortable: false,
    field: 'friend_request_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'request']}
        description={DESCRIPTION_FRIEND_REQUEST}
      />
    ),
  },
  {
    sortable: false,
    field: 'friend_accept_daysAfter_cnt',
    headerClassName: 'borderRight',
    cellClassName: 'borderRight',
    renderHeader: () => (
      <MultilineHeader
        names={['friend', 'accept']}
        description={DESCRIPTION_FRIEND_ACCEPT}
      />
    ),
  },

  // cnt ContacsSyncBanner
  {
    sortable: false,
    field: 'contactsSyncBanner_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['contacts', 'sync', 'banner']}
        description={DESCRIPTION_CONTACTS_SYNC_BANNER}
      />
    ),
  },

  // cnt Phonebook
  {
    sortable: false,
    field: 'phonebook_daysAfter_cnt',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'sync']}
        description={DESCRIPTION_PHONEBOOK_SYNC}
      />
    ),
  },
  {
    sortable: false,
    field: 'sendSMS_daysAfter_cnt',
    headerClassName: 'borderRight',
    cellClassName: 'borderRight',
    renderHeader: () => (
      <MultilineHeader
        names={['phonebook', 'send SMS']}
        description={DESCRIPTION_PHONEBOOK_SEND_SMS}
      />
    ),
  },
]

export const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: 'this_date',
    headerName: 'Number of Records on This Day',
    headerClassName: 'bgDays',
    headerAlign: 'center',
    children: [
      {
        groupId: 'this_date_user',
        headerName: 'Regular Data Check',
        headerClassName: 'headerBorder',
        headerAlign: 'center',
        children: [
          { field: 'user_total' },
          { field: 'firstOpen_cnt' },
          { field: 'firstOpen_ratio' },
          { field: 'sendCodeButtonFirstTap_cnt' },
          { field: 'user_new' },
          { field: 'user_registration_rate' },
          { field: 'user_quit' },
          { field: 'dau' },
        ],
      },
      {
        groupId: 'Unique User',
        headerName: '',
        headerAlign: 'center',
        children: [
          { field: 'updateFullName_ppl' },
          { field: 'updateAvatarUrl_ppl' },
          { field: 'updateBio_ppl' },
          { field: 'moment_ppl' },
          { field: 'moment_like_ppl' },
          { field: 'moment_comment_ppl' },
          { field: 'chatroom_ppl' },
          { field: 'chatroom_join_ppl' },
          { field: 'chatroom_leave_ppl' },
          { field: 'chatMessage_ppl' },
          { field: 'friend_request_ppl' },
          { field: 'friend_accept_ppl' },
          { field: 'contactsSyncBanner_ppl' },
          { field: 'phonebook_ppl' },
          { field: 'sendSMS_ppl' },
        ],
      },
      {
        groupId: 'Unique User Ratios（％）',
        headerName: '',
        headerAlign: 'center',
        children: [
          { field: 'updateFullName_ratio' },
          { field: 'updateAvatarUrl_ratio' },
          { field: 'updateBio_ratio' },
          { field: 'moment_rate' },
          { field: 'moment_like_ratio' },
          { field: 'moment_comment_ratio' },
          { field: 'chatroom_ratio' },
          { field: 'chatroom_join_ratio' },
          { field: 'chatroom_leave_ratio' },
          { field: 'chatMessage_ratio' },
          { field: 'friend_request_ratio' },
          { field: 'friend_accept_ratio' },
          { field: 'contactsSyncBanner_ratio' },
          { field: 'phonebook_ratio' },
          { field: 'sendSMS_ratio' },
        ],
      },
      {
        groupId: 'this_date_cnt',
        headerName: 'Total Event',
        headerClassName: 'headerBorder',
        headerAlign: 'center',
        children: [
          { field: 'updateFullName_cnt' },
          { field: 'updateAvatarUrl_cnt' },
          { field: 'updateBio_cnt' },
          { field: 'moment_cnt' },
          { field: 'moment_like_cnt' },
          { field: 'moment_comment_cnt' },
          { field: 'chatroom_cnt' },
          { field: 'chatroom_join_cnt' },
          { field: 'chatroom_leave_cnt' },
          { field: 'chatMessage_cnt' },
          { field: 'friend_request_cnt' },
          { field: 'friend_accept_cnt' },
          { field: 'contactsSyncBanner_cnt' },
          { field: 'phonebook_cnt' },
          { field: 'sendSMS_cnt' },
        ],
      },
    ],
  },
  {
    groupId: 'this_date_following_N_days',
    headerName: 'User Retention Rate (this_date_following_N_days)',
    headerClassName: 'bgDaysAfter',
    headerAlign: 'center',
    children: [
      {
        groupId: 'Unique Users Over N Days',
        headerName: '',
        headerAlign: 'center',
        children: [
          { field: 'updateFullName_daysAfter_ppl' },
          { field: 'updateAvatarUrl_daysAfter_ppl' },
          { field: 'updateBio_daysAfter_ppl' },
          { field: 'moment_daysAfter_ppl' },
          { field: 'moment_like_daysAfter_ppl' },
          { field: 'moment_comment_daysAfter_ppl' },
          { field: 'chatroom_daysAfter_ppl' },
          { field: 'chatroom_join_daysAfter_ppl' },
          { field: 'chatroom_leave_daysAfter_ppl' },
          { field: 'chatMessage_daysAfter_ppl' },
          { field: 'friend_request_daysAfter_ppl' },
          { field: 'friend_accept_daysAfter_ppl' },
          { field: 'contactsSyncBanner_daysAfter_ppl' },
          { field: 'phonebook_daysAfter_ppl' },
          { field: 'sendSMS_daysAfter_ppl' },
        ],
      },
      {
        groupId: 'Unique User Ratios Over N Days（％）',
        headerName: '',
        headerAlign: 'center',
        children: [
          { field: 'updateFullName_daysAfter_ratio' },
          { field: 'updateAvatarUrl_daysAfter_ratio' },
          { field: 'updateBio_daysAfter_ratio' },
          { field: 'moment_daysAfter_ratio' },
          { field: 'moment_like_daysAfter_ratio' },
          { field: 'moment_comment_daysAfter_ratio' },
          { field: 'chatroom_daysAfter_ratio' },
          { field: 'chatroom_join_daysAfter_ratio' },
          { field: 'chatroom_leave_daysAfter_ratio' },
          { field: 'chatMessage_daysAfter_ratio' },
          { field: 'friend_request_daysAfter_ratio' },
          { field: 'friend_accept_daysAfter_ratio' },
          { field: 'contactsSyncBanner_daysAfter_ratio' },
          { field: 'phonebook_daysAfter_ratio' },
          { field: 'sendSMS_daysAfter_ratio' },
        ],
      },
      {
        groupId: 'this_date_following_N_days_cnt',
        headerName: 'Total Event',
        headerClassName: 'headerBorder',
        headerAlign: 'center',
        children: [
          { field: 'updateFullName_daysAfter_cnt' },
          { field: 'updateAvatarUrl_daysAfter_cnt' },
          { field: 'updateBio_daysAfter_cnt' },
          { field: 'moment_daysAfter_cnt' },
          { field: 'moment_like_daysAfter_cnt' },
          { field: 'moment_comment_daysAfter_cnt' },
          { field: 'chatroom_daysAfter_cnt' },
          { field: 'chatroom_join_daysAfter_cnt' },
          { field: 'chatroom_leave_daysAfter_cnt' },
          { field: 'chatMessage_daysAfter_cnt' },
          { field: 'friend_request_daysAfter_cnt' },
          { field: 'friend_accept_daysAfter_cnt' },
          { field: 'contactsSyncBanner_daysAfter_cnt' },
          { field: 'phonebook_daysAfter_cnt' },
          { field: 'sendSMS_daysAfter_cnt' },
        ],
      },
    ],
  },
]

export const initFormData = {
  from: moment().subtract(14, 'day'),
  to: moment().utcOffset(TIMEZONES[0].offset),
  timezone: TIMEZONES[0].name,
  storeCountryCode: 'ALL',
  daysAfter: 7,
}

function MultilineHeader(props: { names: string[], description: string }) {
  const { names, description } = props
  let lineNo = 0

  return (
    <Tooltip title={description}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ lineHeight: '1rem' }}
      >
        {
            names.map((name) => (
              <Grid item key={++lineNo}>{name}</Grid>
            ))
          }
      </Grid>
    </Tooltip>
  )
}
