import React, { useEffect, useRef, useState } from 'react'
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Autocomplete,
  TextField,
  TablePagination,
} from '@mui/material'
import {
  FirstPage as FirstPageIcon, LastPage as LastPageIcon, KeyboardArrowRight, KeyboardArrowLeft,
} from '@mui/icons-material'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import WidgetTagsAuth, { expectedWidgetUpdateDialogTag, expectedWidgetDeleteDialogTag } from '../../../common/WidgetTagsAuth'
import UserProfileLink from '../../../user/UserProfileLink'
import { DialogDetailsType } from '../types'
import { useDeleteParticipants, useGetMemberDialogs } from '../../../../api/query/dialogs/dialogs'

interface ConfirmationModalState {
  open: boolean;
  title: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: () => void;
}
interface ParticipantData {
  quickbloxUserId: number
  userName: string
  fullName: string
  role: string
  isOfficial: boolean
  isStaff: boolean
  isAmbassador: boolean
  customData: {
    avatarUrl: string
  }
}
interface ParticipantProps {
  is1on1Chat: boolean,
  details: DialogDetailsType,
  handleOpen: (config: Omit<ConfirmationModalState, 'open'>) => void,
  quickbloxDialogId: string | undefined,
}

function ParticipantsTable({
  is1on1Chat, details, handleOpen, quickbloxDialogId,
}: ParticipantProps) {
  const deleteParticipant = useDeleteParticipants()
  const rowsPerPageList = [25, 50, 100]
  const labelRowsPerPageList = 'Rows per page'
  const searchColumns = [
    { label: 'Full Name', field: 'fullName' },
    { label: 'UserName', field: 'userName' },
    { label: 'QuickbloxUserId', field: 'quickbloxUserId' },
    { label: 'Role', field: 'role' },
  ]
  const [searchColumn, setSearchColumn] = useState(searchColumns[0])
  const [searchTerm, setSearchTerm] = useState('')
  const [hasSearched, setHasSearched] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageList[0])
  const [offsetPage, setOffsetPage] = useState(0)

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOffsetPage(0)
    setCurrentPage(1)
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm)
  const [memberDetails, setMemberDetails] = useState({
    participants: [] as Array<ParticipantData>,
    totalRows: 0,
  })
  const queryParams = {
    searchField: searchColumn.field,
    searchValues: debouncedSearchTerm,
    limit: rowsPerPage,
    offset: offsetPage,
  }

  const { data: membersData, isError } = useGetMemberDialogs(quickbloxDialogId, queryParams)
  useEffect(() => {
    if (membersData) {
      setMemberDetails({
        participants: membersData.rows,
        totalRows: membersData.totalRows,
      })
    }
  }, [membersData])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm)
    }, 600) // 0.6s debounce
    return () => {
      clearTimeout(handler)
    }
  }, [searchTerm])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOffsetPage(0)
    setCurrentPage(1)
    setSearchTerm(e.target.value)
    setHasSearched(true)
  }

  const inputRef = useRef<HTMLInputElement>(null)
  if (isError) return <Typography>Error loading participants</Typography>
  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h4" gutterBottom component="span">
            {`Participants (${memberDetails.totalRows})`}
          </Typography>
          <Box display="flex" alignItems="center">
            <TextField
              label="Search"
              variant="standard"
              value={searchTerm}
              inputRef={hasSearched ? inputRef : undefined}
              onChange={handleSearchChange}
              sx={{ width: 150, mr: 2, height: 68 }}
            />
            <Autocomplete
              disablePortal
              options={searchColumns}
              value={searchColumn}
              defaultValue={searchColumns[0]}
              disableClearable
              sx={{ width: 140, height: 68 }}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              renderInput={(params) => <TextField {...params} label="in the Column" variant="standard" />}
              onChange={(event, newValue) => {
                if (newValue) {
                  setSearchColumn(newValue);
                }
              }}
            />
          </Box>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>FullName</TableCell>
                <TableCell>UserName</TableCell>
                <TableCell>QuickbloxUserId</TableCell>
                {!is1on1Chat && (
                  <>
                    <TableCell>Role</TableCell>
                    <TableCell />
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {memberDetails.participants.map((participant: ParticipantData) => (
                <TableRow
                  key={participant.quickbloxUserId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {participant.fullName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {`@${participant.userName}`}
                  </TableCell>
                  <TableCell>
                    <UserProfileLink userId={participant.quickbloxUserId} />
                  </TableCell>
                  {!is1on1Chat && (
                    <>
                      <TableCell>{participant.role}</TableCell>
                      <WidgetTagsAuth expectedWidgetTag={expectedWidgetUpdateDialogTag || expectedWidgetDeleteDialogTag}>
                        <TableCell>
                          {details.creatorUserId !== participant.quickbloxUserId && (
                            <IconButton
                              color="error"
                              onClick={() => handleOpen({
                                title: `Remove ${participant.userName} as participant? Once removed, you can't undo this operation.`,
                                confirmLabel: 'Delete',
                                onConfirm: () => {
                                  deleteParticipant.mutate({
                                    quickbloxDialogId: details.quickbloxDialogId ?? '',
                                    qbUserId: participant.quickbloxUserId,
                                  });
                                  setMemberDetails((prevDetails) => ({
                                    ...prevDetails,
                                    participants: prevDetails.participants.filter((p) => p.quickbloxUserId !== participant.quickbloxUserId),
                                  }));
                                },
                              })}
                            >
                              <PersonRemoveIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </WidgetTagsAuth>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={2} display="flex" justifyContent="flex-start">
          <TablePagination
            component="div"
            sx={{
              '.MuiToolbar-root': { paddingLeft: '5px' },
            }}
            count={memberDetails.totalRows}
            page={currentPage - 1}
            onPageChange={(event, newPage) => {
              setCurrentPage(newPage + 1)
              setOffsetPage(newPage * rowsPerPage)
            }}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelRowsPerPage={labelRowsPerPageList}
            rowsPerPageOptions={rowsPerPageList}
            ActionsComponent={CustomPaginationActions}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

function CustomPaginationActions({
  count,
  page,
  rowsPerPage,
  onPageChange,
}: {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}) {
  const lastPage = Math.ceil(count / rowsPerPage) - 1
  const buttons = [
    {
      i: 0, newPage: 0, disabled: page === 0, icon: <FirstPageIcon />,
    },
    {
      i: 1, newPage: page - 1, disabled: page === 0, icon: <KeyboardArrowLeft />,
    },
    {
      i: 2, newPage: page + 1, disabled: page >= lastPage, icon: <KeyboardArrowRight />,
    },
    {
      i: 3, newPage: lastPage, disabled: page >= lastPage, icon: <LastPageIcon />,
    },
  ]

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {buttons.map((btn) => (
        <IconButton
          key={btn.i}
          onClick={(event) => onPageChange(event, btn.newPage)}
          disabled={btn.disabled}
        >
          {btn.icon}
        </IconButton>
      ))}
    </div>
  )
}

export default ParticipantsTable
