import React, { useEffect, useState } from 'react'
import { EVENT } from '../../constants'
import eventBus from '../../services/eventBus'
import MomentDetails from './MomentDetails'
import MomentGrid from './MomentGrid'

export interface MomentInfo {
  id: number
  quickbloxUserId: number
  name: string
  userName: string
  identifier: string
  contents: {
    top: {
      mediaPublicId: string
      thumbnailPublicId: string
    }
    bottom: {
      mediaPublicId: string
      thumbnailPublicId: string
    }
  }
  visible: boolean
  commentNum?: number
  likeNum?: number
  visibility: string
  createdAt: string
  updatedAt: string
  deletedAt?: string
}

function Moments() {
  const [showGrid, setShowGrid] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [momentIdentifier, setMomentIdentifier] = useState('')

  function handleExitDetails(deleted: boolean) {
    eventBus.dispatch(EVENT.SHOW_MOMENT_GRID)
  }

  useEffect(() => {
    function showGridHelper() {
      setMomentIdentifier('')
      setRefresh((r) => !r)
      setShowGrid(true)
    }

    function showDetailsHelper(e: CustomEvent) {
      setShowGrid(false)
      setMomentIdentifier(e.detail.identifier)
    }

    eventBus.on(EVENT.SHOW_MOMENT_GRID, showGridHelper)
    eventBus.on(EVENT.SHOW_MOMENT_DETAILS, showDetailsHelper)

    return () => {
      eventBus.remove(EVENT.SHOW_MOMENT_GRID, showGridHelper)
      eventBus.remove(EVENT.SHOW_MOMENT_DETAILS, showDetailsHelper)
    }
  }, [])

  return (
    <>
      <MomentGrid
        show={showGrid}
        refresh={refresh}
      />
      <MomentDetails
        identifier={momentIdentifier}
        backText="Moments"
        exitDetails={handleExitDetails}
      />
    </>
  )
}

export default Moments
